
export default [

  //public route test
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("../views/support/maintenance.vue"),
  },
  //public route test end

  {
    path: "/stats",
    name: "stats",
    component: () => import("../views/stats/platform-stats.vue"),
    meta: {
      title: "Statistiche",
      authRequired: true,
    },
  },

  {
    path: "/",
    name: "users",
    component: () => import("../views/users/users-list.vue"),
    meta: {
      title: "Utenti",
      authRequired: true,
    },
  },
  {
    path: "/sellers",
    name: "sellers",
    component: () => import("../views/sellers/sellers-list.vue"),
    meta: {
      title: "Commerciali",
      authRequired: true,
    },
  },

  {
    path: "/sellers/:id",
    name: "seller",
    component: () => import("../views/sellers/seller-detail.vue"),
    meta: {
      title: "Dettaglio Commerciali",
      authRequired: true,
    },
    props: true,
  },
  
  {
    path: "/billings",
    name: "billings",
    component: () => import("../views/billing/billings-list.vue"),
    meta: {
      title: "Fatturazione",
      authRequired: true,
    },
  },
  {
    path: "/billing/:id",
    name: "billing summary",
    component: () => import("../views/billing/billing-summary.vue"),
    meta: {
      title: "Dettaglio Fatturazione",
      authRequired: true,
    },
    props: true,
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../views/support/index.vue"),
    meta: {
      title: "Supporto",
      authRequired: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/settings/index.vue"),
    meta: {
      title: "Impostazioni",
      authRequired: true,
    },
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import("../views/customers/customers-list.vue"),
    meta: {
      title: "Clienti",
      authRequired: true,
    },
  },
  {
    path: "/customers/:id",
    name: "customer",
    component: () => import("../views/customers/customer-detail.vue"),
    meta: {
      title: "Dettaglio Clienti",
      authRequired: true,
    },
    props: true,
  },
  {
    path: "/users/:id",
    name: "userDetail",
    component: () => import("../views/users/user-detail.vue"),
    meta: {
      title: "Dettaglio Utenti",
      authRequired: true,
    },
    props: true,
  },
  
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: false,
    },
    component: () => import("../views/auth/logout/basic"),
  },
  //My login
  {
    path: "/login",
    name: "login",
    meta: {
      title: "login",
      guestRequired: true,
    },
    component: () => import("../views/auth/signin/login"),
  },
  //My login End
  //My forgot password
  {
    path: "/recover-password",
    name: "recover-password",
    meta: {
      title: "Recupera Password",
      guestRequired: true,
    },
    component: () => import("../views/auth/reset/recoverPassword.vue"),
  },
  //My forgot password End
  //My password reset
  {
    path: '/reset-password/:emailBase64/:token',
    name: 'ResetPassword',
    meta: {
      title: "Nuova Password",
      guestRequired: true,
    },
    component: () => import("../views/auth/reset/resetPassword.vue"),
    props: true
  },
  //My password reset End

  //404 page
  {
    path: "/:pathMatch(.*)",
    name: "not-found",
    meta: {
      title: "Pagina non trovata",
    },
    component: () => import("../views/auth/errors/404-cover.vue"),
  }
];
