import { defineStore } from "pinia";
// import { $axios } from "@/httpHelper/axios-instances";
// import { toast } from "@/utils/toast";
export const useUserStore = defineStore("user", {
  state: () => ({
    isLogged: localStorage.getItem("isLogged") === "true", //confronto diretto con stringa "true", evita conversioni di tipo, restituendo un booleano.
    user: {},
  }),
  actions: {
    // logout() {
    //   $axios.post( `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/auth/logout`)
    // .then(() => {
    //   this.isLogged = false;
    //   localStorage.setItem("isLogged", "false");
    //   this.$router.push("/");
    // })
    // .catch((err) => {
    //   toast.error(err.response.data.message);
    // });
    // }
  }
});