<template>
 <router-view></router-view>

</template>

<script>

export default {
  name: 'App',
  mounted(){
    this.$store.dispatch('layout/changeMode', { mode: localStorage.getItem('theme') });

    if(process.env.VUE_APP_DEBUG === "false"){
      console.log = () => {};
    }
  }
}
</script>
